<template>
  <v-container>
    <h1>Gestion des Information Pratiques du site</h1>
    <h2>Gestion des tarifs</h2>
    <v-form ref="updateForm">
      <v-row class="mb-4">
        <v-col cols="6" v-for="(tarif,index) in tarifs">
          <v-card>
            <v-card-title>
              <v-text-field v-model="tarif.title" :label="$t('Titre du tarif')"/>
              <v-divider light/>
              <v-btn color="red" dark @click="tarifs.splice(index,1)">X</v-btn>
            </v-card-title>
            <v-card-text>
              <wysiwyg v-model="tarif.text"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="tarifs.push({title:'',text:''})">Ajouter tarif</v-btn>
      <v-btn color="primary" @click="save">
        <v-icon>mdi-briefcase-download</v-icon>
        Sauver
      </v-btn>
    </v-form>
    <Loading :visible="isLoading"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';

const servicePrefix = 'GlobalParams';

export default {
  name: 'InfosPratiques',
  servicePrefix,
  components: {
    Loading,
  },
  data() {
    return {
      item: null,
      tarifs: [],
    }
  },
  created() {
    this.isLoading = false;
    this.retrieve(decodeURIComponent(this.$route.name))
        .then(() => {
          this.item = this.find(decodeURIComponent(this.$route.name));
          if(this.item.value.tarifs)
          {
            this.tarifs = this.item.value.tarifs
          }
        })
        .catch(e => {
          console.error(e);
        })
  },
  computed: {
    ...mapFields('globalParam', {
      isLoading: 'isLoading'
    }),
    ...mapGetters({
      find: 'globalParam/find',
    }),
  },
  methods: {
    ...mapActions('globalParam', {
      retrieve: 'load',
      update: 'update'
    })
    ,
    save() {
      this.update(Object.assign(this.item, {'value': {'tarifs': this.tarifs}}));
    }
  }
  ,
  watch: {}
}
</script>
